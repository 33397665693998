<template>
  <div class="container">
    <div class="checkin">
      <el-row gutter="20">
        <el-col span="5">
          <memberleft :activeIndex="leftPannelSelected" />
        </el-col>
        <el-col span="19">
          <div class="right_pannel" style="padding-top:0px">
            <div
              class="pannel-top"
              style="width: 100%; height: 150px"
            >
                <img src="https://static.a6air.com/resource/banner/20210330/pannel_top_changepwd.jpg" alt="">
            </div>
            <!-- <div class="panel-header" style="width: 800px;margin-left: 20px;;">
                            <ul class="panel-tabs" style="width:400px;">
                                <li class="active">
                                    <a href="javascript:void(0)">修改密码</a>
                                </li>
                            </ul>
                        </div> -->
            <div style="width: 700px; margin: auto">
              <ul class="service-forms">
                <li class="form-group">
                  <label class="form-label" for="certNo">手机号：</label>
                  <el-input
                    id="certNo"
                    ref="phone"
                    v-model="changePwdRequest.userName"
                    disabled
                    placeholder="请输入手机号"
                    style="width: 350px"
                  ></el-input>
                  <span class="form-required">*</span>
                </li>
                <li class="form-group">
                  <label class="form-label" show-password for="password"
                    >新密码：</label
                  >
                  <el-input
                    id="password"
                    ref="password"
                    v-model="changePwdRequest.newPwd"
                    placeholder="请输入新密码"
                    style="width: 350px"
                    maxlength="16"
                    @input="checkPwd"
                    show-password
                  ></el-input>
                  <div class="strength-container">
                    <div
                      class="strength-bar clearfix"
                      style="visibility: visible"
                    >
                      <div
                        :style="
                          pwdStrong >= 1
                            ? 'background-color: #e86868;'
                            : 'background-color: #cdcdcd;'
                        "
                      ></div>
                      <div
                        :style="
                          pwdStrong >= 2
                            ? 'background-color:rgb(222, 52, 52);'
                            : 'background-color: #cdcdcd;'
                        "
                      ></div>
                      <div
                        :style="
                          pwdStrong >= 4
                            ? 'background-color:rgb(175, 9, 9);'
                            : 'background-color:#cdcdcd;'
                        "
                      ></div>
                    </div>
                    <p class="strength-text">
                      <span>弱</span>
                      <span>中</span>
                      <span>强</span>
                    </p>
                  </div>
                  <p
                    style="
                      margin: 0 0 0 100px;
                      padding: 10px 0 0;
                      font-size: 12px;
                      font-weight: 400;
                      line-height: 1;
                      color: #9d9d9d;
                      text-align: left;
                    "
                  >
                    密码由大小写字母、数字、特殊字符组成的8到16位字符，至少包含字母、数字组合
                  </p>
                </li>
                <li class="form-group">
                  <label class="form-label" show-password for="confirmPassword"
                    >确认密码：</label
                  >
                  <el-input
                    id="confirmPassword"
                    ref="confirmPassword"
                    maxlength="16"
                    v-model="changePwdRequest.confirmPassword"
                    placeholder="请输入确认密码"
                    style="width: 350px"
                    show-password
                  ></el-input>
                  <span class="form-required">*</span>
                </li>

                <li class="form-group">
                  <div class="filter-input">
                    <el-row>
                      <el-col :span="10">
                        <label class="form-label" for="certNo">验证码：</label>
                        <el-input
                          :clearable="true"
                          v-model="changePwdRequest.picValidateCode"
                          ref="picValidateCode"
                          placeholder="请输入图形验证码"
                          style="width: 180px; height: 40px !important"
                        >
                        </el-input>
                      </el-col>
                      <el-col :span="10" style="display: flex">
                        <el-image
                          style="
                            width: 100px;
                            padding-top: 5px;
                            padding-left: 10px;
                          "
                          :src="picValidateUrl"
                          @click="changeValidateCode"
                        ></el-image>
                        <div @click="changeValidateCode" class="change-btn">
                          看不清，换一张
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </li>
                <li class="form-group" style="display: flex">
                  <label class="form-label" for="certNo">短信验证码：</label>
                  <el-input
                    id="phoneValidateCode"
                    ref="phoneValidateCode"
                    v-model="changePwdRequest.phoneValidateCode"
                    placeholder="请输入验证码"
                    style="width: 180px"
                  >
                    <!-- <el-button slot="append" icon="el-icon-search"
                                                   @click="fetchPhoneValidateCodeHandler"
                                                   v-loading="callphoneloading"
                                                   :disabled="(phoneCodeIntervalMax<=0 || phoneCodeIntervalMax>=60)?false:true">
                                            {{phoneValidateCodeLabel}}
                                        </el-button> -->
                    <span class="form-required">*</span>
                  </el-input>
                  <el-button
                    style="margin-left: 10px"
                    class="validate-btn"
                    @click="fetchPhoneValidateCodeHandler"
                    v-loading="callphoneloading"
                    :disabled="
                      phoneCodeIntervalMax <= 0 || phoneCodeIntervalMax >= 60
                        ? false
                        : true
                    "
                    >{{ phoneValidateCodeLabel }}</el-button
                  >
                </li>
                <li>
                  <div
                    style="
                      text-align: center;
                      width: 100%;
                      padding-bottom: 5px;
                      margin-top: 30px;
                    "
                  >
                    <el-button
                      type="danger"
                      style="width: 100px; border-radius: 5px"
                      @click="updateUserHandler"
                      >确&nbsp;认</el-button
                    >
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import memberleft from "../../components/MemberLeft";
import { changePwd, fetchPhoneValidateCode, userinfo } from "@/api/newsapi";
import { CURRENT_USER } from "@/store/mutation-types";

export default {
  components: { memberleft },
  data() {
    return {
      input: "",
      phoneValidateCodeLabel: "获取验证码",
      phoneCodeIntervalMax: 60,
      phoneCodeInvalid: false,
      leftPannelSelected: "changepwd",
      callphoneloading: false,
      pwdStrong: 0,
      pwdOk:false,
      baseValidateUrl: "/api/user/createCaptcha?random=",
      picValidateUrl: "",
      validateCodeRequest: {
        phone: "",
        picValidateCode: "",
        action: "",
        sendType: "changepwd",
      },
      changePwdRequest: {
        userName: "",
        newPwd: "",
        confirmPassword: "",
        picValidateCode: "",
        phoneValidateCode: "",
      },
    };
  },
  created() {
    this.picValidateUrl = this.baseValidateUrl + new Date().getTime();
  },
  mounted() {
    this.currentUser = Vue.ls.get(CURRENT_USER);
    if (
      this.currentUser.phone == undefined ||
      this.currentUser.phone.length <= 0
    ) {
      this.$router.push({ path: "/login", query: { t: new Date().getTime() } });
    }
    userinfo({ t: {} }).then((res) => {
      if (res.success) {
        this.changePwdRequest.userName = res.data.phone;
      }
    });
  },
  methods: {
    fetchPhoneValidateCodeHandler() {
      if (this.changePwdRequest.userName.length != 11) {
        this.$message.error("手机号码校验不通过，请核实输入11位手机号");
        this.$refs.phone.focus();
        return;
      }
      if (this.changePwdRequest.picValidateCode.length < 4) {
        this.$message.error("请先输入图像验证码!");
        this.$refs.picValidateCode.focus();
        return;
      }

      if (
        this.changePwdRequest.newPwd != this.changePwdRequest.confirmPassword
      ) {
        this.$message.error("两次密码输入不一致，请重新输入");
        this.$refs.confirmPassword.focus();
        return;
      }
      this.validateCodeRequest.phone = this.changePwdRequest.userName;
      this.validateCodeRequest.picValidateCode = this.changePwdRequest.picValidateCode;
      this.callphoneloading = true;
      fetchPhoneValidateCode(this.validateCodeRequest).then((res) => {
        this.callphoneloading = false;
        if (res.success) {
          this.phoneCodeIntevel();
          console.log(res);
        } else {
          this.$message.error(res.errorMessage);
        }
      });
    },
    changeValidateCode() {
      this.picValidateUrl = this.baseValidateUrl + new Date().getTime();
    },
    checkPwd() {
      console.log("aa:" + this.changePwdRequest.newPwd);
      if (this.changePwdRequest.newPwd.length < 8) {
          this.pwdStrong = 0;
        return;
      }
      let val = this.changePwdRequest.newPwd; //获取输入的值
      let numReg = RegExp(/[0-9]/); //判断是否存在数字
      let aaaReg = RegExp(/[a-z]/); //判断是否存在小写字符
      let AAAReg = RegExp(/[A-Z]/); //判断是否存在大写字符
      //正则判断
      let num, aaa, AAA, FFF=false;
      numReg.test(val) ? (num = true) : (num = false);
      aaaReg.test(val) ? (aaa = true) : (aaa = false);
      AAAReg.test(val) ? (AAA = true) : (AAA = false);

      for (var i = 0; i < val.length; i++) {
            //测试每一个字符的类别并统计一共有多少种模式.
           var fType=this.getCharType(val.charCodeAt(i));
           if(fType==8){
            FFF=true;
           }
      }

      if((num && aaa) || (num && AAA)){
        this.pwdOk=true;
      }

      //将结果做成一个数组
      let result = [num, aaa, AAA,FFF];
      let len = result.length;
      //定义有几种类型
      let type = 0;
      for (let i = 0; i < len; i++) {
        if (result[i]) {
          type++;
        }
      }

      this.pwdStrong = type;

      
    },
    getCharType(iN) {
      if (iN >= 48 && iN <= 57)
        //数字
        return 1;
      if (iN >= 65 && iN <= 90)
        //大写字母
        return 2;
      if (iN >= 97 && iN <= 122)
        //小写
        return 4;
      else return 8; //特殊字符
    },
    updateUserHandler() {
      if (this.changePwdRequest.newPwd.length <= 0) {
        this.$message.error("密码不能为空，请重新输入");
        this.$refs.password.focus();
        return;
      }
      if (
        this.changePwdRequest.newPwd.length < 8 ||
        this.changePwdRequest.newPwd.length > 16
      ) {
        this.$message.error("密码需要为8-16个字符，请核实重新输入");
        this.$refs.password.focus();
        return;
      }
      if (
        this.changePwdRequest.newPwd != this.changePwdRequest.confirmPassword
      ) {
        this.$message.error("两次密码输入不一致，请重新输入");
        this.$refs.confirmPassword.focus();
        return;
      }
      if(!this.pwdOk){
        this.$message.error("您的密码太简单，请至少包含数字及字母");
        this.$refs.password.focus();
        return;
      }
      if (this.changePwdRequest.phoneValidateCode.length != 6) {
        this.$message.error("短信验证码输入不正确,请重新输入");
        this.$refs.phoneValidateCode.focus();
        return;
      }

      changePwd(this.changePwdRequest).then((res) => {
        if (res.success) {
          this.changePwdRequest.newPwd = undefined;
          this.changePwdRequest.confirmPassword = undefined;
          this.changePwdRequest.phoneValidateCode = undefined;
          this.changePwdRequest.picValidateCode = undefined;
          this.$message({
            message: "修改密码成功!",
            confirmButtonText: "确定",
            type: "success",
          });
        } else {
          this.$message.error(res.errorMessage);
        }
      });
    },
    selectPannel(pannelId) {
      document.getElementById("li_passlist").setAttribute("class", "");
      document.getElementById("li_order").setAttribute("class", "");
      document.getElementById("li_changepwd").setAttribute("class", "");
      document.getElementById("li_userinfo").setAttribute("class", "");
      document.getElementById(pannelId).setAttribute("class", "active");
    },
    phoneCodeIntevel() {
      var that = this;
      console.log("倒计时");
      that.phoneCodeIntervalMax = 60;
      that.phoneCodeInvalid = false;
      var interval = setInterval(function () {
        console.log("倒计时");
        console.log(that.phoneCodeIntervalMax);
        var remainSecond = that.phoneCodeIntervalMax--;
        if (remainSecond <= 0) {
          clearInterval(interval);
          that.phoneCodeInvalid = true;
          that.phoneValidateCodeLabel = "获取验证码";
          return;
        }
        that.phoneValidateCodeLabel = remainSecond + "秒后重新获取";
      }, 1000);
    },
  },
};
</script>
<style scoped lang='less'>
.form-group .form-label {
  width: 100px;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 35px;
  color: #4d4e4e;
  text-align: right;
}

.checkin-links.query {
  margin: 24px 35px 24px 75px !important;
}

.form-group .form-required {
  margin-left: 8px;
  font-size: 26px;
  font-weight: 700;
  padding-top: 4px;
  color: #e34c4c;
  display: inline-block;
  height: 35px;
  line-height: 35px;
  vertical-align: middle;
}

.checkin {
  margin-top: 20px;
  margin-bottom: 20px;
}

.left_pannel {
  height: 500px;
  background-color: #f5f5f5;
  overflow: hidden;
}

.right_pannel {
  position: relative;
  /* background-color: #f5f5f5; */
  /* border: 1px solid #ededed; */
}

.col_menu b {
  font-size: 24px;
  margin: 20px 20px 0;
  color: #333;
  display: block;
  font-weight: normal;
  border-bottom: 1px solid #f1f1f1;
}

.col_menu ul {
  margin-bottom: 20px;
}

ul,
ol,
dl,
dd,
dt {
  margin: 0;
  padding: 0;
  display: block;
}

.col_menu li {
  margin: 10px 0;
  position: relative;
}

.col_menu li.current {
  background: none;
}

.col_menu li.current a:link,
.col_menu li.current a:visited,
.col_menu li.current a:active {
  border-left: 2px #ff6600 solid;
  color: #666;
  font-weight: bold;
}

.col_menu li a:link,
.col_menu li a:visited,
.col_menu li a:active {
  display: block;
  line-height: 30px;
  padding: 0 55px 0 20px;
}

a:link,
a:visited,
a:active {
  color: #666;
  text-decoration: none;
}

a {
  color: #337ab7;
  text-decoration: none;
}

.warm-prompt {
  padding: 40px 50px 80px;
}

.warm-prompt p {
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  color: #9d9d9d;
  text-align: left;
}

.order-search .service-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 55px;
  color: #4d4e4e;
  text-align: center;
  border-bottom: 1px solid #eae7e7;
}

.order-search .service-prompt {
  padding-top: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.8;
  color: #9d9d9d;
  text-align: center;
}

.order-search .service-forms > li {
  margin-top: 20px;
}

.form-group .form-label {
  width: 100px;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 35px;
  color: #4d4e4e;
  text-align: right;
}

.checkin-links.query {
  margin: 24px 35px 24px 75px !important;
}

.agree-protocol {
  margin: 24px 0;
  padding-left: 24px;
  font-size: 0;
}

.link.link-sm {
  height: 18px;
  font-size: 12px;
  line-height: 18px;
}

.link {
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #2c6e8b;
  text-align: left;
}

.search-block {
  width: 1200px;
  margin: auto;
  position: relative;
}

.popcontext .tab {
  z-index: 1000;
  width: 240px;
}

.popcontext .tab li {
  height: 60px;
  line-height: 60px;
  /* border-top: 1px solid #4e617f; */
  background-color: #f5f5f5;
  color: #000000;
  font-size: 14px;
  text-align: center;
  transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  cursor: pointer;
  margin-left: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  filter: alpha(Opacity=60);
  -moz-opacity: 0.6;
  opacity: 0.6;
}

.popcontext .tab li:last-child {
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-bottom-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.popcontext .tab li.active {
  /* border-top: 1px solid #fff; */
  background-color: #d6003a;
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
  margin-left: 0;
  padding-left: 10px;
  border-right: 1px solid #fff;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
  filter: alpha(Opacity=100);
  -moz-opacity: 1;
  opacity: 1;
}

.popcontext hgroup {
  display: none;
  position: absolute;
  width: 460px;
  top: -410px;
  left: 0px;
  z-index: 1000;
  margin-left: 140px;
  background-color: #fff;
  -webkit-border-radius: 0 5px 5px 0;
  -moz-border-radius: 0 5px 5px 0;
  border-radius: 0 5px 5px 0;
}

.popcontext hgroup article {
  position: relative;
  height: 364px;
  padding-left: 30px;
}

.service-query {
  position: relative;
  z-index: 10;
  margin: 0 auto;
  padding: 8px 44px 0;
}

.service-query .service-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 55px;
  color: #4d4e4e;
  text-align: center;
  border-bottom: 1px solid #eae7e7;
}

.service-query .service-prompt {
  padding-top: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.8;
  color: #9d9d9d;
  text-align: center;
}

.service-forms > li {
  margin-top: 15px;
}

.panel-header {
  border-bottom: 2px solid #e9e8e8;
  margin-top: 10px;
  width: 400px;
}

.panel-header .panel-tabs {
  display: block;
  width: 100%;
  height: 42px;
  margin: 0;
  padding: 0;
}

.panel-tabs li {
  width: 50%;
  position: relative;
  float: left;
}

.panel-tabs li a {
  position: relative;
  display: block;
  margin: auto;
  width: 80px;
  height: 42px;
  font-size: 16px;
  font-weight: 400;
  line-height: 42px;

  text-align: center;
  cursor: pointer;
}

.panel-tabs li.active:after {
  position: absolute;
  right: 0;
  bottom: -2px;
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  content: "";
  background-color: #ce212e;
}

.panel-tabs .clearfix:after {
  display: block;
  height: 0;
  content: "";
  clear: both;
  overflow: hidden;
}

.strength-container {
  position: relative;
  top: 5px;
  display: inline-block;
  margin-left: 16px;
  width: 192px;
  vertical-align: middle;
}

.strength-container .strength-bar {
  display: block;
  padding: 2px;
  height: 9px;
  border: 1px solid #b7b7b7;
  border-radius: 8px;
}

.strength-container .strength-bar div {
  display: block;
  float: left;
  width: 60px;
  height: 8px;
  background-color: #cdcdcd;
}

.strength-container .strength-bar div:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.strength-container .strength-bar div:nth-child(2) {
  margin: 0 3px;
}

.strength-container .strength-bar div:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.strength-container .strength-text {
  padding: 4px 0 0;
  font-size: 12px;
  font-weight: normal;
  line-height: 1;
  color: #4d4e4e;
  text-align: center;
}

.strength-container .strength-text span {
  display: inline-block;
  width: 33.33%;
}

.strength-container.weak > .strength-bar div:first-child {
  background-color: #eb3701;
}

.strength-container.middle > .strength-bar div:first-child {
  background-color: #eb3701;
}

.strength-container.middle > .strength-bar div:nth-child(2) {
  background-color: #f88214;
}

.strength-container.strong > .strength-bar div:first-child {
  background-color: #eb3701;
}

.strength-container.strong > .strength-bar div:nth-child(2) {
  background-color: #f88214;
}

.strength-container.strong > .strength-bar div:last-child {
  background-color: #6aa001;
}
.pannel-top {
//   background-image: url("../../assets/index-1.jpg");
//   border-radius: 10px 10px 0 0;
    img{
        width: 100%;
        height: 150px;
        border-radius: 10px 10px 0 0;
    }
}
.change-btn {
  text-align: left;
  font-size: 12px;
  margin-left: 10px;
  color: #009ed0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.validate-btn {
  width: 100px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d70039;
  cursor: pointer;
  border-radius: 5px;
  background: #ffffff;
  color: #4d4e4e;
}
</style>
